<template>
  <div class="container">
    <div class="vertical-line-container">
      <div class="vertical-line-item red-line"></div>
      <div class="vertical-line-info">
        <h3 class="text-red">Monthly fixed price</h3>
        <h3 class="text-yellow">Unlimited design</h3>
        <h3 class="text-blue">Pause or cancel anytime</h3>
      </div>
      <div class="vertical-line-item blue-line"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VerticalLineComponent'
}
</script>