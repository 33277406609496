<template>
  <div class="request-design-btn">
    <a href="https://calendly.com/reabovstudio/15min" target="_blank">request a design...</a>
  </div>
</template>

<script>
export default {
  name: "RequestButtonComponent"
}
</script>
