<template>
    <div class="copyright">
        <div class="colors">
          <div class="color-item"></div>
          <div class="color-item"></div>
          <div class="color-item"></div>
          <div class="color-item"></div>
          <div class="color-item"></div>
          <div class="color-item"></div>
          <div class="color-item"></div>
          <div class="color-item"></div>
          <div class="color-item"></div>
        </div>
        <span>REABOV STUDIO © 2024 </span>
        <span>Developed by | DIONIS PASCARU</span>
    </div>
</template>

<script>
export default {
    name: 'CopyrightComponent'
}
</script>
