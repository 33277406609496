<template>
  <div>
    <div class="membership-benefits" id="BenefitsId">
      <div class="">
        <h2>We did’t reinvent the wheel. Just design</h2>
      </div>
      <div class="membership-benefits-content">
        <!--membership benefits item-->
        <div class="membership-benefits-item">
          <div class="membership-benefits-image">
            <img src="@/assets/images/membership/icons/c1-subscription.svg">
          </div>
          <div class="membership-benefits-info">
            <p>Subscribe to a plan & request as many designs as you'd like.</p>
          </div>
        </div>
        <!--membership benefits item-->
        <div class="membership-benefits-item move-down">
          <div class="membership-benefits-image">
            <img src="@/assets/images/membership/icons/c1-fast.svg">
          </div>
          <div class="membership-benefits-info">
            <p>Get your design one at a time in just a few days on average.</p>
          </div>
        </div>
        <!--membership benefits item-->
        <div class="membership-benefits-item">
          <div class="membership-benefits-image">
            <img src="@/assets/images/membership/icons/c1-satisfied.svg">
          </div>
          <div class="membership-benefits-info">
            <p>We'll revise the designs until you're 100% satisfied.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="membership-benefits">
      <div class="membership-block-title">
        <h2>Membership benefits</h2>
      </div>
      <div class="membership-benefits-block-title">
        <h3>Get your design pack services in one Payment</h3>
      </div>
      <div class="membership-benefits-content">
        <!--membership benefits item-->
        <div class="membership-benefits-item">
          <div class="membership-benefits-image">
            <img src="@/assets/images/membership/icons/c2-trello.svg">
          </div>
          <div class="membership-benefits-title">
            <h3>Trello board</h3>
          </div>
          <div class="membership-benefits-info">
            <p>Easily manage your design tasks with a Trello board.</p>
          </div>
        </div>
        <!--membership benefits item-->
        <div class="membership-benefits-item move-down">
          <div class="membership-benefits-image">
            <img src="@/assets/images/membership/icons/c2-fast-delivery.svg">
          </div>
          <div class="membership-benefits-title">
            <h3>Fast delivery</h3>
          </div>
          <div class="membership-benefits-info">
            <p>Receive your design within three business days on average</p>
          </div>
        </div>
        <!--membership benefits item-->
        <div class="membership-benefits-item">
          <div class="membership-benefits-image">
            <img src="@/assets/images/membership/icons/c2-quality.svg">
          </div>
          <div class="membership-benefits-title">
            <h3>Highest quality</h3>
          </div>
          <div class="membership-benefits-info">
            <p>Insane design quality at your fingertips whenever you need it.</p>
          </div>
        </div>
        <!--membership benefits item-->
        <div class="membership-benefits-item">
          <div class="membership-benefits-image">
            <img src="@/assets/images/membership/icons/c2-scale.svg">
          </div>
          <div class="membership-benefits-title">
            <h3>Flexible and scalable</h3>
          </div>
          <div class="membership-benefits-info">
            <p>Scale up or down as needed, and pause or cancel at anytime.</p>
          </div>
        </div>
        <!--membership benefits item-->
        <div class="membership-benefits-item move-down">
          <div class="membership-benefits-image">
            <img src="@/assets/images/membership/icons/c2-fixed-monthly.svg">
          </div>
          <div class="membership-benefits-title">
            <h3>Fixed monthly rate</h3>
          </div>
          <div class="membership-benefits-info">
            <p>No surprises here! Pay the same fixed price each month.</p>
          </div>
        </div>
        <!--membership benefits item-->
        <div class="membership-benefits-item">
          <div class="membership-benefits-image">
            <img src="@/assets/images/membership/icons/c2-unique.svg">
          </div>
          <div class="membership-benefits-title">
            <h3>Unique and all yours</h3>
          </div>
          <div class="membership-benefits-info">
            <p>Each of your designs is made especially for you and is 100% yours.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MembershipBenefitsComponent',
}
</script>