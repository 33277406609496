<template>
  <div class="container">
    <div class="contact">
      <div class="contact-info">
        <h3>Thx U</h3>
      </div>
      <div class="contact-button">
        <a target="_blank" href="https://www.buymeacoffee.com/reabovstudio">
          <img src="@/assets/thnu.svg">
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactComponent',
}
</script>
